import { Form, Modal, Stack } from 'react-bootstrap';
import React from 'react';
import { ActionButton } from 'components/action-button';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { RexLogin } from 'schemas/rex.zod';
import { useMe } from 'hook/use-me';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import produce from 'immer';
import { UserZod } from '../../../../schemas/user.zod';

const schemaZod = z.object({
  login: z.string().email(),
  password: z.string().min(1),
  account_id: z.string().optional()
});
type SchemaZod = z.infer<typeof schemaZod>;

export function RexLoginModal() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SchemaZod>({
    resolver: zodResolver(schemaZod)
  });
  const queryClient = useQueryClient();
  const { isFetching } = useMe();
  const {
    mutate: rexLogin,
    isLoading,
    isError,
    error
  } = useMutation<AxiosResponse<RexLogin>, Error, SchemaZod>(payload => axios.post('/user-rexes', payload), {
    onSuccess(response) {
      queryClient.setQueryData(['/user-rexes/account'], response);
      queryClient.setQueryData<AxiosResponse<UserZod>>(
        ['/users/me'],
        produce(draft => {
          if (draft) {
            draft.data.userRex = response.data.userRex;
          }
        })
      );
    },
    onError(e) {
      console.log(e);
    }
  });

  const onSubmit = (data: SchemaZod) => {
    rexLogin(data);
  };

  const errorMessage = axios.isAxiosError(error) ? error.response?.data.detail : 'An error has occurred';

  return (
    <Modal show={true} centered>
      <Modal.Header className={'bg-primary'}>
        <Modal.Title className={'fs-5 text-white'}>Connect to Rex</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'login-rex'}>
          <Stack className={''} gap={3}>
            <Form.FloatingLabel controlId="floatingInput" label="Email address">
              <Form.Control autoComplete={'username'} data-testid={'email'} type="email" placeholder="name@example.com" {...register('login')} isInvalid={!!errors.login} />
            </Form.FloatingLabel>
            <Form.FloatingLabel controlId="floatingPassword" label="Password">
              <Form.Control
                autoComplete={'current-password'}
                data-testid={'password'}
                type="password"
                placeholder="Password"
                {...register('password')}
                isInvalid={!!errors.password}
              />
            </Form.FloatingLabel>

            {isError && <p className={'text-danger text-center'}>{errorMessage}</p>}
          </Stack>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <ActionButton isLoading={isLoading || isFetching} form={'login-rex'} type={'submit'}>
          Connect
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );
}
