import { z } from 'zod';

export const loginZod = z.object({
  username: z.string().email(),
  password: z.string().min(1),
  rememberMe: z.boolean().optional()
});
export type LoginZod = z.infer<typeof loginZod>;

export const forgotPasswordZod = z.object({
  email: z.string().email()
});
export type ForgotPasswordZod = z.infer<typeof forgotPasswordZod>;
