import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export default function Logout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    localStorage.removeItem('auth');
    sessionStorage.removeItem('auth');
    queryClient.getQueryCache().clear();

    navigate('/login', { replace: true });
  }, [navigate, queryClient]);

  return <div></div>;
}
