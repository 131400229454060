import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Logo } from '../logo';

import style from './style.module.scss';

export function Footer() {
  return (
    <footer className={`${style.footer} d-flex align-items-center mt-5`}>
      <Container>
        <Row className={'align-items-center'}>
          <Col xs={4}>
            <Logo height={25} />
          </Col>
          {/*<Col className={'d-flex justify-content-end'}>*/}
          {/*  <p> - </p>*/}
          {/*</Col>*/}
        </Row>
      </Container>
    </footer>
  );
}
