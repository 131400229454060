import { Spinner } from 'react-bootstrap';

type Props = {
  fullscreen?: boolean;
};
export function SuspenseLoader({ fullscreen = false }: Props) {
  return (
    <div data-testid={'suspense-loader'} className={`w-100 d-flex align-items-center justify-content-center ${fullscreen ? 'vh-100' : ''}`}>
      <Spinner animation={'grow'} />
    </div>
  );
}
