import React from 'react';
import { Link } from 'react-router-dom';

import style from './style.module.scss';

type Props = {
  to?: string;
  href?: string;
  label: string | JSX.Element;
  Icon: JSX.Element;
  disabled?: boolean;
  id: number | string;
};
export function CardLink({ to, Icon, label, disabled, href, id }: Props) {
  if (disabled) {
    return (
      <button className={`${style.card} d-flex flex-column align-items-center text-center disabled`} disabled>
        <p className={style.soon}>
          Coming
          <br />
          Soon
        </p>
        <span className={'mt-4 mb-4'}>{Icon}</span>
        {label}
      </button>
    );
  }

  if (href) {
    return (
      <a href={href} className={`${style.card} d-flex flex-column align-items-center text-center`} target={'_blank'} rel={'noreferrer'}>
        <span className={'mt-4 mb-4'}>{Icon}</span>
        {label}
      </a>
    );
  }

  return (
    <Link to={to as string} state={{ id }} className={`${style.card} d-flex flex-column align-items-center text-center pre-wrap`}>
      <span className={'mt-4 mb-4'}>{Icon}</span>
      {label}
    </Link>
  );
}
