export enum ROLES_API {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_SUPPORT = 'ROLE_SUPPORT',
  ROLE_REP = 'ROLE_REP',
  ROLE_PA = 'ROLE_PA'
}

export enum LISTING_STATUS {
  STARTED = 'STARTED',
  READY = 'READY',
  LIVE = 'LIVE',
  PRICE_ADJUSTMENT = 'PRICE_ADJUSTMENT',
  WITHDRAW = 'WITHDRAW',
  FALL_OVER = 'FALL_OVER'
}

export enum ENTITIES {
  PRICE_ADJUSTMENT = 'PriceAdjustment',
  WITHDRAW = 'Withdraw',
  FALL_OVER = 'FallOver'
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface Pagination<T> {
  limit: number;
  page: number;
  results: T[];
  total: number;
  totalPages: number;
}

export type FileUploadType = File & { id?: number };
