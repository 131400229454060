import { Button, ButtonProps } from 'react-bootstrap';

import style from './style.module.scss';
import SpinnerLoader from 'components/spinner';

interface Props {
  isLoading?: boolean;
}
export function ActionButton({ isLoading, children, ...rest }: Props & ButtonProps) {
  const className = rest.className;
  delete rest.className;

  return (
    <Button className={`position-relative ${className ?? ''}`} disabled={isLoading} {...rest}>
      <span style={{ opacity: isLoading ? 0 : 1 }}>{children}</span>
      {isLoading && (
        <div className={`${style.spinner}`}>
          <SpinnerLoader size={'sm'} stretch variant={'light'} />
        </div>
      )}
    </Button>
  );
}
