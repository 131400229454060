import { Button, Form, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import { useGlobalLoader } from '../../../hook/use-global-loader';
import { useGlobalMessage } from '../../../hook/use-global-message';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { forgotPasswordZod, ForgotPasswordZod } from '../../../schemas/login.zod';
import { zodResolver } from '@hookform/resolvers/zod';

import style from '../style.module.scss';

export function ForgotPassword() {
  const { showLoader, hideLoader } = useGlobalLoader();
  const { showMessage } = useGlobalMessage();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordZod>({
    resolver: zodResolver(forgotPasswordZod)
  });

  const { mutate } = useMutation(['reset-password'], (variables: ForgotPasswordZod) => axios.post('/auth/send-reset-password', variables), {
    onSuccess(data) {
      console.log('onSuccess', data);
      showMessage('Check your e-mail!');
    },
    onError(error) {
      console.log('onError', error);
      showMessage(error, true);
    },
    onSettled(data) {
      console.log('onSettled', data);
      hideLoader();
    }
  });

  const onSubmit = (data: ForgotPasswordZod) => {
    showLoader();
    mutate(data);
  };

  return (
    <>
      <h2 className={'text-center mt-5 mb-5'}>Please enter your e-mail</h2>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack className={'align-items-center'} gap={3}>
          <Form.FloatingLabel controlId="floatingInput" label="Email address">
            <Form.Control data-testid={'email'} type="email" placeholder="name@example.com" {...register('email')} isInvalid={!!errors.email} />
          </Form.FloatingLabel>
          <Button data-testid={'submit'} type={'submit'}>
            Submit
          </Button>
          <Link to={'/login'} className={style.forgot} replace>
            Back
          </Link>
        </Stack>
      </Form>
    </>
  );
}
