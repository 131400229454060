import { useEffect, useState } from 'react';
import { usePWAInstall } from 'react-use-pwa-install';
import { Button, Modal } from 'react-bootstrap';
import style from './style.module.scss';
import Icon from '@mdi/react';
import { mdiExportVariant } from '@mdi/js';
import { useMe } from '../../hook/use-me';
import { useDevice } from '../../hook/use-device';
import dayjs from 'dayjs';

export function InstallPrompt() {
  const { data: me } = useMe();
  const device = useDevice();
  const [show, setShow] = useState(true);
  const [render, setRender] = useState(false);
  const install = usePWAInstall(); //android

  const showRexLoginModal = me && me.userRex === undefined;
  const showRexAccountModal = me && me.userRex?.rexAccount === null;
  const mightShowPrompt = !!(me && !showRexLoginModal && !showRexAccountModal);

  const close = () => setShow(false);

  useEffect(() => {
    const lastTime = localStorage.getItem('install-prompt');
    const diff = dayjs(lastTime).diff(dayjs(), 'd');

    if (device === 'ios') {
      let isStandalone = false;
      if ('standalone' in window.navigator) isStandalone = Boolean(window.navigator.standalone);

      if (!isStandalone && mightShowPrompt && (lastTime == null || diff > 14)) {
        localStorage.setItem('install-prompt', dayjs().format());
        setRender(true);
      }
    } else {
      if (install !== null && mightShowPrompt && (lastTime == null || diff > 6)) {
        localStorage.setItem('install-prompt', dayjs().format());
        setRender(true);
      }
    }
  }, [install, mightShowPrompt, device]);

  //only for testing purposes.
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', function (event) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      // console.log('beforeinstallprompt', event);
    });
  }, []);

  const onClickInstall = () => {
    close();
    install();
  };

  // console.log({install, device, isIOS, isSafari})

  if (!render) return null;

  return (
    <Modal show={show} centered onHide={close}>
      <Modal.Body>
        <div className={'d-flex flex-column align-items-center text-center'}>
          <img
            src={`/${process.env.REACT_APP_BRAND_FOLDER}/squared-icon.png`}
            alt={`Install ${process.env.REACT_APP_BRAND_HTML_TITLE}`}
            width={100}
            height={100}
            className={style.logo}
          />
          <h2 className={'mt-5 mb-3 pt-4'}>Install {process.env.REACT_APP_BRAND_HTML_TITLE}</h2>
          <p>Install this application on your homescreen for a better experience.</p>
          {device === 'ios' && (
            <p data-testid={'prompt-ios'} className={'d-flex align-items-center'}>
              Tap <Icon path={mdiExportVariant} size={1} className={'ms-1 me-1 text-primary'} /> then "Add to Home Screen".
            </p>
          )}
          <div className={'d-flex'}>
            <Button data-testid={'prompt-close'} variant={'outline-primary'} size={'sm'} onClick={close}>
              CLOSE
            </Button>
            {device === 'android' && (
              <Button data-testid={'prompt-install'} className={'ms-3'} variant={'primary'} size={'sm'} onClick={onClickInstall}>
                INSTALL
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
