import React from 'react';

import style from './style.module.scss';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Logo } from '../logo';
import { ReactComponent as MenuSVG } from '../../images/icons/menu.svg';
import { useSidebar } from '../../hook/use-sidebar';
import { NavLink } from 'react-router-dom';

export function Header() {
  const { openSidebar } = useSidebar();

  return (
    <header className={style.header}>
      <Container className={'d-flex'}>
        <Row className={'w-100 align-items-center g-0'}>
          <Col className={'d-flex justify-content-between h-100'}>
            <div className={'d-flex align-items-center gap-4 fs-6'}>
              <Nav.Link as={NavLink} to={'/requests'}>
                <Logo height={40} />
              </Nav.Link>

              <div className={`links d-none d-md-flex align-items-center h-100 ${style.links}`}>
                <Nav.Link as={NavLink} to={'/requests'} className={'link-primary'}>
                  REQUESTS
                </Nav.Link>

                <Nav.Link as={NavLink} to={'/dashboard'} className={'link-primary'}>
                  DASHBOARD
                </Nav.Link>

                <Nav.Link as={NavLink} to={'/profile'} className={'link-primary'}>
                  MY PROFILE
                </Nav.Link>
              </div>
            </div>
            <button type={'button'} className={'p-0 burger'} onClick={openSidebar}>
              <MenuSVG />
            </button>
          </Col>
        </Row>
      </Container>
    </header>
  );
}
