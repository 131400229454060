import { useProjectTheme } from '../../hook/theme';

type Props = {
  width?: number;
  height?: number;
};
export function Logo({ width, height }: Props) {
  const { data } = useProjectTheme();

  if (data) {
    const originalWidth = data.brand.logo.width;
    const originalHeight = data.brand.logo.height;
    const headerLogo = data.brand.headerLogo;

    let w, h;
    if (width && height) {
      w = width;
      h = height;
    } else if (width) {
      const p = width / originalWidth;
      h = originalHeight * p;
    } else if (height) {
      const p = height / originalHeight;
      w = originalWidth * p;
    }

    return (
      <img src={`/${process.env.REACT_APP_BRAND_FOLDER}/${headerLogo || 'logo.svg'}`} alt={process.env.REACT_APP_BRAND_HTML_TITLE} className={'brand-logo'} width={w} height={h} />
    );
  }

  return <></>;
}
