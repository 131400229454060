import React, { PropsWithChildren, useEffect } from 'react';
import { Header } from '../header';
import { Col, Container, Row } from 'react-bootstrap';
import { Footer } from '../footer';
import { InstallPrompt } from '../install-prompt';
import { RexLoginModal } from '../rex/modal/login';
import { RexAccountsModal } from '../rex/modal/accounts';
import { GlobalConfirmation } from '../global-confirmation';
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useMe } from '../../hook/use-me';
import { Sidebar } from '../sidebar';
import { filesAtom } from '../../pages/requests';

export function Template({ children }: PropsWithChildren) {
  const location = useLocation();
  const [, setFiles] = useAtom(filesAtom);
  const { data: me } = useMe();

  //Reset files stored. If it hadn't been here, we should have cleaned up this atom's state in every page using it.
  useEffect(() => {
    setFiles([]);
  }, [location, setFiles]);

  const showRexLoginModal = me && me.userRex == null;
  const showRexAccountModal = !showRexLoginModal && me && me.userRex?.rexAccount == null;

  return (
    <GlobalConfirmation>
      <Sidebar>
        <main className={'app'}>
          <Header />
          <Container as={'section'}>
            <Row>
              <Col className={`pt-5 mt-5`}>{children}</Col>
            </Row>
          </Container>
          <Footer />
        </main>
      </Sidebar>
      <InstallPrompt />
      {showRexLoginModal && <RexLoginModal />}
      {showRexAccountModal && <RexAccountsModal />}
    </GlobalConfirmation>
  );
}
