import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { UserZod } from '../../schemas/user.zod';

export const queryFnMe = (): Promise<UserZod> => axios.get('/users/me');
export const queryKeyMe = ['/users/me'];

export function useMe() {
  return useQuery(queryKeyMe, queryFnMe);
}
