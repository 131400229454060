import React, { createContext, ReactNode, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

type ShowConfirmationProps = {
  title: string;
  text: ReactNode;
  okButton?: string;
  cancelButton?: string | null;
  onSuccess?: () => void;
  onCancel?: () => void;
};
type ContextProps = {
  showConfirmation: ({ title, text, okButton, cancelButton, onSuccess, onCancel }: ShowConfirmationProps) => void;
  hideConfirmation: () => void;
};
export const GlobalConfirmationContext = createContext<ContextProps>({ showConfirmation: () => null, hideConfirmation: () => null });

type State = Omit<ShowConfirmationProps, 'onSuccess' | 'onCancel'> & {
  show: boolean;
};

const initialState: State = {
  text: '',
  show: false,
  title: '',
  okButton: 'Ok',
  cancelButton: 'Cancel'
};

type Props = {
  children: ReactNode;
};
export function GlobalConfirmation({ children }: Props) {
  const [state, setState] = useState<State>(initialState);

  const refOnSuccess = useRef<(() => void) | undefined>();
  const refOnCancel = useRef<(() => void) | undefined>();
  const refOnExited = useRef<(() => void) | undefined>();

  const showConfirmation = ({ title, text, okButton, cancelButton, onSuccess, onCancel }: ShowConfirmationProps) => {
    refOnSuccess.current = onSuccess;
    refOnCancel.current = onCancel;

    setState({
      text,
      title,
      show: true,
      okButton: okButton ?? 'Ok',
      cancelButton: cancelButton === null ? null : cancelButton ?? 'Cancel'
    });
  };

  const hideConfirmation = (callback?: () => void) => {
    onHide();
  };

  const onHide = () => {
    setState({ ...state, show: false });
  };

  const onExited = () => {
    if (refOnExited.current) refOnExited.current();

    setState(initialState);
  };

  const onClickCancel = () => {
    refOnExited.current = refOnCancel.current;
    onHide();
  };

  const onClickSuccess = () => {
    refOnExited.current = refOnSuccess.current;
    onHide();
  };

  const { show, text, title, okButton, cancelButton } = state;

  return (
    <GlobalConfirmationContext.Provider value={{ showConfirmation, hideConfirmation }}>
      {children}
      <Modal data-testid={`global-confirmation`} show={show} onHide={onHide} onExited={onExited} centered>
        <Modal.Header closeButton closeVariant={'white'}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={'d-flex justify-content-center'}>{text}</Modal.Body>

        <Modal.Footer>
          {cancelButton && (
            <Button variant="secondary" onClick={onClickCancel}>
              {cancelButton}
            </Button>
          )}
          {okButton && (
            <Button variant="primary" onClick={onClickSuccess}>
              {okButton}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </GlobalConfirmationContext.Provider>
  );
}
