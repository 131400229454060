import { Navigate, useSearchParams } from 'react-router-dom';

export default function SetToken() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (token) {
    localStorage.setItem('auth', token);
    return <Navigate to={'/requests'} replace />;
  } else return <Navigate to={'/login'} replace />;
}
