import { Button, Form, Stack } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import style from '../style.module.scss';
import React, { useEffect, useState } from 'react';
import { useGlobalLoader } from '../../../hook/use-global-loader';
import { useGlobalMessage } from '../../../hook/use-global-message';
import { useForm } from 'react-hook-form';
import { useLogin } from '../../../hook/use-login';
import { useQueryClient } from '@tanstack/react-query';
import Microsoft from '../../../images/icons/microsoft.svg';
import { loginZod, LoginZod } from '../../../schemas/login.zod';
import { zodResolver } from '@hookform/resolvers/zod';

export function SignIn() {
  const queryClient = useQueryClient();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { showMessage } = useGlobalMessage();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginZod>({
    resolver: zodResolver(loginZod)
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(searchParams.get('error'));

  const { mutate } = useLogin({
    onSuccess(data) {
      console.log('onSuccess', data);
      navigate('/requests', { replace: true });
    },
    onError(error) {
      console.log('onError', error);
      showMessage(error, true);
    },
    onSettled(data) {
      console.log('onSettled', data);
      hideLoader();
    }
  });

  const onSubmit = (data: LoginZod) => {
    showLoader();
    setError(null);
    mutate(data);
  };

  useEffect(() => {
    queryClient.clear();
  }, [queryClient]);

  return (
    <>
      <h2 className={'text-center mt-5 mb-5'}>Please sign in</h2>
      {error && <p className={'text-danger text-center'}>{error}</p>}
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack className={'align-items-center'} gap={3}>
          <Form.FloatingLabel controlId="floatingInput" label="Email address">
            <Form.Control autoComplete={'username'} data-testid={'email'} type="email" placeholder="name@example.com" {...register('username')} isInvalid={!!errors.username} />
          </Form.FloatingLabel>
          <Form.FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              autoComplete={'current-password'}
              data-testid={'password'}
              type="password"
              placeholder="Password"
              {...register('password')}
              isInvalid={!!errors.password}
            />
          </Form.FloatingLabel>
          <Form.Check id={'remember-me'} type={'checkbox'} label={'Remember me'} {...register('rememberMe')} />
          <Button data-testid={'submit'} type={'submit'}>
            Sign in
          </Button>
          <Link to={'/login/forgot-password'} className={style.forgot}>
            Forgot password
          </Link>
          <p className={'m-0'}>or</p>
          <a href={process.env.REACT_APP_API_URL + '/active-directory/auth'} className={'d-flex align-items-center justify-content-center gap-3 p-2 border border-1'}>
            <img src={Microsoft} width={30} height={30} alt={'Microsoft'} />
            <span>Sign in with Microsoft</span>
          </a>
        </Stack>
      </Form>
    </>
  );
}
