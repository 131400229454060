import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { LoginZod } from '../../schemas/login.zod';

export function useLogin(options?: Omit<UseMutationOptions<unknown, unknown, LoginZod, unknown>, 'mutationFn' | 'mutationKey'>) {
  return useMutation(['/auth'], (variables: LoginZod): any => axios.post<any>('/auth', variables), {
    ...options,
    onSuccess(response: AxiosResponse, variables: LoginZod, context) {
      localStorage.removeItem('auth');
      sessionStorage.removeItem('auth');

      if (variables.rememberMe) {
        localStorage.setItem('auth', response.data.token);
      } else {
        sessionStorage.setItem('auth', response.data.token);
      }

      if (options?.onSuccess) {
        options.onSuccess(response, variables, context);
      }
    }
  });
}
