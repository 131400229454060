import React, { createContext, ReactNode, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import style from './style.module.scss';

type ContextProps = {
  showLoader: () => void;
  hideLoader: () => void;
  isShowingLoader: boolean;
};
export const GlobalLoaderContext = createContext<ContextProps>({ showLoader: () => null, hideLoader: () => null, isShowingLoader: false });

type Props = {
  children: ReactNode;
};
export function GlobalLoader({ children }: Props) {
  const [show, setShow] = useState(false);
  const refOnHide = useRef<(() => void) | null>(null);

  const showLoader = () => setShow(true);
  const hideLoader = (callback?: () => void) => {
    if (callback) refOnHide.current = callback;
    setShow(false);
  };
  const onHide = () => {
    if (refOnHide.current) refOnHide.current();
    refOnHide.current = null;
  };

  return (
    <GlobalLoaderContext.Provider value={{ showLoader, hideLoader, isShowingLoader: show }}>
      {children}
      <Modal data-testid={'global-loader'} show={show} onHide={onHide} centered className={style['global-loader']}>
        <Modal.Body className={'d-flex justify-content-center'}>
          <Spinner animation={'border'} variant={'light'} />
        </Modal.Body>
      </Modal>
    </GlobalLoaderContext.Provider>
  );
}
