import React from 'react';
import { Outlet } from 'react-router-dom';
import { Template } from '../../components/template';

export function Protected() {
  return (
    <Template>
      <Outlet />
    </Template>
  );
}
