import { Form, Modal, Stack } from 'react-bootstrap';
import React from 'react';
import { ActionButton } from 'components/action-button';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useMe } from 'hook/use-me';
import { RexLogin } from 'schemas/rex.zod';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const schemaZod = z.object({
  account_id: z.string().min(1)
});
type SchemaZod = z.infer<typeof schemaZod>;

export function RexAccountsModal() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SchemaZod>({
    resolver: zodResolver(schemaZod)
  });
  const { data: rexLogin } = useQuery<RexLogin>(['/user-rexes/account']);
  const queryClient = useQueryClient();
  const { isFetching } = useMe();
  const {
    mutate: rexAccount,
    isLoading,
    isError,
    error
  } = useMutation<unknown, Error, SchemaZod>(payload => axios.put('/user-rexes/account', payload), {
    onSuccess() {
      queryClient.invalidateQueries(['/users/me']);
    },
    onError(e) {
      console.log(e);
    }
  });

  const onSubmit = (data: SchemaZod) => {
    rexAccount(data);
  };

  const errorMessage = axios.isAxiosError(error) ? error.response?.data.detail : 'An error has occurred';

  return (
    <Modal show={true} centered>
      <Modal.Header className={'bg-primary'}>
        <Modal.Title className={'fs-5 text-white'}>Select Rex Account</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'accounts-rex'}>
          <Stack className={''} gap={3}>
            <Form.Select {...register('account_id')} isInvalid={!!errors.account_id}>
              <option hidden value={''}>
                Select account...
              </option>
              {rexLogin?.accounts.map(acc => (
                <option key={acc.account_id} value={acc.account_id}>
                  {acc.name}
                </option>
              ))}
            </Form.Select>
            {isError && <p className={'text-danger text-center'}>{errorMessage}</p>}
          </Stack>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <ActionButton isLoading={isLoading || isFetching} form={'accounts-rex'} type={'submit'}>
          Ok
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );
}
