import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { queryFnMe, queryKeyMe } from '../hook/use-me';
import { queryFnTheme, queryKeyTheme } from '../hook/theme';

export function mainLoader(queryClient: QueryClient) {
  return async function ({ request }: LoaderFunctionArgs) {
    try {
      await queryClient.fetchQuery(queryKeyTheme, queryFnTheme);
    } catch (e) {
      return redirect('/error');
    }

    try {
      await queryClient.fetchQuery(queryKeyMe, queryFnMe);
    } catch (e) {
      localStorage.clear();
      return redirect('/login');
    }

    return null;
  };
}
