import { useState, useEffect } from 'react';

type Props = {
  isIOS: boolean | undefined;
  isSafari: boolean | undefined;
};
function checkForIOS(): Props {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.navigator.userAgent.includes('MSIE') && !window.navigator.userAgent.includes('Trident');
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return { isIOS, isSafari };
}

export function useIsIOS() {
  const [isIOS, setIsIOS] = useState<Props>({ isIOS: undefined, isSafari: undefined });

  useEffect(() => {
    setIsIOS(checkForIOS());
  }, []);

  return isIOS;
}
