import { Spinner, SpinnerProps } from 'react-bootstrap';

interface Props {
  stretch?: boolean;
  className?: string;
}
export default function SpinnerLoader({ size, variant = 'primary', stretch = false, animation = 'border', className = '' }: Partial<SpinnerProps> & Props) {
  return (
    <div className={`${stretch ? 'd-flex w-100 h-100 justify-content-center align-items-center' : ''} ${className}`}>
      <Spinner animation={animation} variant={variant} className={`fs-7`} size={size} />
    </div>
  );
}
