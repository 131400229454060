import React, { createContext, ReactNode, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import clsx from 'clsx';

type ContextProps = {
  showMessage: (source: any, isError?: boolean, title?: string) => void;
  hideMessage: () => void;
};
export const GlobalMessageContext = createContext<ContextProps>({ showMessage: () => null, hideMessage: () => null });

type Props = {
  children: ReactNode;
};
export function GlobalMessage({ children }: Props) {
  const [source, setSource] = useState<any | null>(null);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [isError, setIsError] = useState(false);
  const refOnHide = useRef<(() => void) | undefined>();

  const showMessage = (message: any, isError: boolean = false, title: string = '') => {
    setSource(message);
    setIsError(isError);
    setTitle(title);
    setShow(true);
  };

  const hideMessage = (callback?: () => void) => {
    refOnHide.current = callback;
    onHide();
  };

  const onHide = () => {
    setShow(false);
  };

  const onExited = () => {
    if (refOnHide.current) refOnHide.current();
    refOnHide.current = undefined;

    setSource(null);
    setTitle('');
  };

  let message = '';

  if (typeof source === 'string') message = source;
  else if (source?.isAxiosError) message = source?.response.data.detail;
  else message = source?.detail || source?.message;

  return (
    <GlobalMessageContext.Provider value={{ showMessage, hideMessage }}>
      {children}
      <Modal data-testid={`global-message-${isError ? 'error' : 'success'}`} show={show} onHide={onHide} onExited={onExited} centered>
        <Modal.Header closeButton closeVariant={'white'} className={clsx('text-white', { 'bg-danger': isError, 'bg-success': !isError })}>
          <Modal.Title>{title ? title : isError ? 'An error has occurred' : 'Success!'}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={'d-flex justify-content-center text-break'}>{message}</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => hideMessage()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </GlobalMessageContext.Provider>
  );
}
